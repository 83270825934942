import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Tooltip, useMediaQuery } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { RELATE_APP_BASE_URL } from "../../../../utils/constants";

import Truncate from "react-truncate";
// import EpisodeDescription from "../../../components/PlayEpisode";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

function ViewCourseEpCard(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();
  const {
    thumbnail,
    title,
    duration,
    day,
    progress,
    episode,
    status,
    watchStatus,
    isPaid,
    isFree,
    error,
    index
  } = props;
  return (
    <div>
      {/* {open && (
        <EpisodeDescription
          open={open}
          episode={episode}
          openHandler={(e) => setOpen(e)}
        />
      )} */}
      <Card className={index===0?classes.root0:classes.root}>
        <CardContent
          style={{
            backgroundImage: `linear-gradient(90deg, #f1f4f6 ${progress}%, #FFFF ${0}%)`,
            height: "100%",
          }}
        >
          <Grid container justify="center" direction="row">
            <Grid xs={4} md={4} lg={4} xl={2} item>
              <div
                style={{
                  background:
                    thumbnail &&
                    `url(${RELATE_APP_BASE_URL}${thumbnail}), #C4C4C4`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                }}
                className={classes.imageContainer}
              />
            </Grid>
            <Grid
              xs={6}
              md={6}
              lg={6}
              xl={8}
              item
              style={{ paddingLeft: "20px" }}
            >
              <Typography
                variant="h2"
                className={classes.Title}
                style={{
                  color: "rgba(0, 0, 0, 0.8)",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {day}
              </Typography>
              <Tooltip title={title}>
                <Typography
                  variant="h2"
                  className={classes.subContent}
                  style={{
                    color: "rgba(0, 0, 0, 0.8)",
                  }}
                >
                  <Truncate
                    lines={isMobile ? 2 : 4}
                    ellipsis={<span>...</span>}
                  >
                    {`${title}`}
                  </Truncate>
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default ViewCourseEpCard;

const useStyles = makeStyles((theme) => ({
  root0: {
    width: "100%",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    lineHeight: "3.625em",
    background: "#fff",
  },
  root: {
    width: "100%",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    marginTop: "50px",
    lineHeight: "3.625em",
    background: "#fff",
  },
  imageContainer: {
    height: 0,
    paddingTop: "56.25%",
    position: "relative",
    borderRadius: "9px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    marginTop: "-5px",
  },
  Title: {
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "12px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.8)",
  },
  subContent: {
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "16px",
    marginTop: "5px",
    color: "rgba(0, 0, 0, 0.8)",
  },
  moreDetail: {
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "16px",
    marginTop: "5px",
    color: "#89CEF2",
    paddingTop: "12px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  videoIcon: {
    height: "3.438em",
    width: "3.438em",
    borderRadius: "100%",
    backgroundColor: "#FFF",
    textAlign: "left",
    marginRight: "20px",
    textAlign: "center",
    cursor: "pointer",
  },
  epIcon: {
    display: "flex",
    alignItems: "center",
  },
  activeEpisode: {
    background: "#52b202",
    color: "#fff",
  },
  inProgressEpisode: {
    background: "#ff9100",
    color: "#fff",
  },
  blockedEpisode: {
    background: "#f44336",
    color: "#fff",
  },
  proceedButton: {
    background: "#fbae02",
    color: "#fff",
    "&:hover": {
      background: "#fbae02",
      color: "#fff",
    },
    "&:focus": {
      background: "#fbae02",
      color: "#fff",
    },
  },
}));
