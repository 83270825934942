import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Typography,
  Avatar,
  CardHeader,
  CardContent,
  Chip,
  Grid,
  Button,
} from "@material-ui/core";
import { Link, navigate } from "gatsby";

import { useIntl } from "react-intl";
// import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";
import Rating from "@material-ui/lab/Rating";
import moment from "moment";
import { LIVE_URL, RELATE_APP_BASE_URL } from "../../../../utils/constants";
import clsx from "clsx";

const hexToRGB = (hex, alpha) => {
  var r = parseInt(hex && hex.slice(1, 3), 16),
    g = parseInt(hex && hex.slice(3, 5), 16),
    b = parseInt(hex && hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

function CourseInstructorIntro(props) {
  const classes = useStyles();
  const { instructor, tags, remarks, status } = props;
  const [expand, setExpand] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const { courseDetail } = props;
  const expandDescription = () => {
    setExpand(!expand);
  };


  return (
    <>
      <Card className={classes.root}>
        <Link
          style={{
            textDecoration: "none",
            color: "#FFFF",
            display: "flex",
            alignItems: "center",
          }}
          to={`/teachers/${instructor?.name?.replace(/\s/g, "-")}-${
            instructor?.id
          }`}
          target="_self"
        >
          <CardHeader
            avatar={
              <Avatar
                aria-label="user's pic"
                src={`${RELATE_APP_BASE_URL}${instructor?.pictureUrl}`}
              >
                {instructor?.name.charAt(0).toUpperCase()}
              </Avatar>
            }
            title={
              <Typography variant="h2" className={classes.instructorIntroTextTitle}>
                {instructor?.name}
              </Typography>
            }
            subheader={
              <Typography variant="h2"
                className={classes.instructorIntroText}
                style={{
                  fontSize: "14px",
                  lineHeight: "19px",
                  color: "#B0B0B0",
                }}
              >
                {instructor?.title}
              </Typography>
            }
          />
        </Link>

        <CardContent>
          <Typography variant="h2"
            className={clsx(
              classes.instructorIntroText,
              classes.instructorIntroTextSubTitle
            )}
            style={{ marginTop: "-40px", lineHeight: "23px" }}
          >
            {instructor?.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: unescape(instructor?.description),
                }}
              />
            )}
          </Typography>

          {/*    <Grid item xs={12}>
            <Grid container>
              <Typography
                className={clsx(classes.font_family, classes.heading)}
              >
                Course Description
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              className={clsx(classes.font_family, classes.description)}
              style={{ marginTop: "10px", lineHeight: "23px" }}
            >
              {remarks && (
                <div dangerouslySetInnerHTML={{ __html: unescape(remarks) }} />
              )}
            </Typography>
          </Grid> */}

          <Grid item xs={12}>
            <Grid container>
              <Typography variant="h2"
                className={clsx(classes.font_family, classes.heading)}
              >
                Course Description
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h2"
              className={clsx(classes.font_family, classes.description)}
              style={{ marginTop: "10px", lineHeight: "23px" }}
            >
              {remarks && (
                <div dangerouslySetInnerHTML={{ __html: unescape(remarks) }} />
              )}
            </Typography>
          </Grid>

          {tags?.length > 0 && (
            <Typography variant="h2" className={clsx(classes.font_family, classes.heading)}>
              {"Course Tags"}
            </Typography>
          )}
          <Grid className={clsx(classes.font_family, classes.description)}>
            {tags?.map((tag, ind) => {
              return (
                <Chip
                  label={tag}
                  key={ind}
                  className={`${classes.instructorIntroText}  ${classes.courseTags}`}
                  style={{
                    fontSize: "14px",
                    color: "#FFF",
                    marginTop: "4px",
                    background: `linear-gradient(360deg, ${hexToRGB(
                      props?.category?.color1,
                      1
                    )} 30%, ${hexToRGB(props?.category?.color2, 1)} 90%)`,
                  }}
                />
              );
            })}
          </Grid>

          <Grid container style={{ paddingTop: "15px" }}>
            <Grid item xs={12}>
              <Typography variant="h2" style={{ display: "flex", alignItems: "center" }}>
                <Rating
                  name="simple-controlled"
                  size={"small"}
                  value={courseDetail?.averageRating}
                  readOnly={true}
                  precision={0.5}
                  classes={{ sizeSmall: classes.ratingSize }}
                />
                <span
                  style={{
                    fontSize: "14px",
                    color: "#83CCF0",
                    marginLeft: "13px",
                  }}
                >
                  {courseDetail?.reviews_ratings?.length > 0
                    ? courseDetail?.reviews_ratings?.length + " "
                    : ""}
                  {!courseDetail?.reviews_ratings?.length
                    ? "No Review"
                    : courseDetail?.reviews_ratings?.length < 1
                    ? "No Review"
                    : courseDetail?.reviews_ratings?.length === 1
                    ? "Review"
                    : "Reviews"}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default CourseInstructorIntro;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0px 16px 20px rgba(123, 0, 0, 0.05)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    background: "#fff",
  },
  instructorIntroText: {
    fontFamily: "Reef",
    fontStyle: "normal",
    fontWeight: 900,
    /* color: "#1B1B1B !important", */
  },
  instructorIntroTextTitle: {
    fontSize: "20px",
    lineHeight: "27px",
    fontFamily: "Reef",
    fontStyle: "normal",
    fontWeight: 900,
    color: "#1B1B1B !important",
  },
  instructorIntroTextSubTitle: {
    fontSize: "16px",
    lineHeight: "26px",
    // color: "#B0B0B0 !important",
    letterSpacing: "0.016em",
    padding: "5px 0",
    color: "#635e5e !important",
  },
  font_family: {
    fontFamily: "Reef",
    fontStyle: "normal",
    fontWeight: 900,
  },
  heading: {
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1B1B1B",
  },
  description: {
    fontSize: "16px",
    lineHeight: "26px",
    // color: "#B0B0B0",
    color: "#635e5e",
    letterSpacing: "0.016em",
  },
  courseTags: {
    background:
      "linear-gradient(180deg, rgba(123, 146, 166, 0.95) 0%, rgba(21, 57, 89, 0.95) 100%)",
    borderRadius: "60px",
    transform: "rotate(3600deg)",
    marginRight: "3px",
  },
  readMore: {
    fontSize: "12px",
    cursor: "pointer",
    color: "#84CDF0",
    "&:hover": {
      textDecoration: "underline",
      color: "#84CDF0",
    },
  },
  ratingSize: {
    fontSize: "15px",
  },
}));
