import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  Typography,
  useMediaQuery,
  CardContent,
} from "@material-ui/core";
import ViewCourseEpCard from "../ViewCourseEpCard/index";

function CourseViewEpisodesCard(props) {
  const theme = useTheme();
  const classes = useStyles();

  let isMobile = useMediaQuery(theme.breakpoints.up("sm")) ? false : true;

  return (
    <Card
      className={classes.episodesCardContainer}
      style={{
        boxShadow: isMobile && "none",
      }}
    >
      <CardContent>
        <Grid container>
          {props?.episodes?.length > 0 ? (
            props?.episodes?.map((episode, ind) => {
              return (
                <Grid item xs={12} key={ind}>
                  <ViewCourseEpCard
                    textColor="#2A4A67"
                    day={`Day ${ind + 1}`}
                    title={episode?.name}
                    duration={episode?.duration}
                    watchStatus={episode?.watchStatus}
                    status={episode?.status}
                    thumbnail={episode?.thumbnail}
                    isFree={episode.isFree}
                    isClickable={true}
                    episode={episode}
                    goNext={false}
                    index={ind}
                  />
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12} className={classes.episodeSubContainers}>
              <Typography variant="h2" className={classes.noRecord}>No Record</Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CourseViewEpisodesCard;

const useStyles = makeStyles((theme) => ({
  episodesCardContainer: {
    width: "100%",
    minHeight: "5.750em",
    borderRadius: "10px",
    boxShadow: "0px 16px 20px rgba(123, 0, 0, 0.05)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    background: "#fff",
  },

  courseTitle: {
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "36px",
    color: "#FFFFFF",
    padding: "4% 0 12% 0",
  },
  noRecord: {
    color: "#FFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    marginTop: "30px",
  },
}));
