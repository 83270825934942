import React from "react";
import { graphql, Link } from "gatsby";
// import ReactMarkdown from "react-markdown"
// import Moment from "react-moment"
import Layout from "../components/layout";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, useMediaQuery, Box } from "@material-ui/core";
import { PICTURE_STRAPI_API_URL } from "../utils/constants";
import parse, { domToReact } from "html-react-parser";
import CourseDetails from "../components/courseDetails/view-course";
const Blog = (props) => {
  const { data } = props;

  const course = props?.pageContext?.course;
  console.log(course, "*************************");
  const blog = props?.data?.strapiBlogs;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));

  const getId = (url) => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return "error";
    }
  };

  const seoData = {
    title: course?.seoTitle,
    description: course?.seoDescription,
    image: course?.image,
    keyword: course?.seoKeywords,
    name: course?.user?.name,
    url: `courses/${course?.slug}`,
  };

  return (
    <Layout seoData={seoData}>
      {/* <div
        className={classes.container}
        style={{ backgroundImage: `url(${blog?.image?.publicURL})` }}
      >
      </div> */}

      <Box
        className={isMobile ? classes.descriptionMob : classes.description}
        style={{ fontSize: isXS ? "12px" : isMobile && "20px" }}
      >
        {/* <ReactMarkdown
          source={blog.content}
          renderers={{ image: Image }}
          transformImageUri={uri =>
            uri.startsWith("http") ? uri : `${PICTURE_STRAPI_API_URL}${uri}`
          }
        /> */}

        <div className="ck-content">
          <CourseDetails courseDetail={course} />
        </div>
      </Box>

      {/* <Grid container justify="flex-end" style={{ marginTop: "5%" }}>
        <Box className={classes.date}>
          <b>Published At: </b>
          <Moment format="MMMM DD, YYYY">{blog.published_at}</Moment>
        </Box>
      </Grid> */}
    </Layout>
  );
};

export default Blog;

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    //    height: '52px'
  },
  container: {
    // minHeight: "360px",
    // width: "100%",
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",

    width: "100%",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: 0,
    position: "relative",
    paddingTop: "33.33%",
  },
  innerContainer: {
    // minHeight: "360px",
    // width: "100%",
    background: "rgba(0,0,0,0.2)",

    display: "flex",
    alignItems: "center",

    // background: "rgba(242,135,5,0.46)",
    position: "absolute",
    left: 0,
    bottom: 0,
    top: 0,
    right: 0,
  },
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "55px",
    lineHeight: "64px",
    color: "#FFFFFF",
    textAlign: "center",
  },

  descHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    textAlign: "center",
    color: "#3D3D3D",
    padding: "14px 35px",
  },
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    // fontWeight: 500,
    fontSize: "20px",
    // lineHeight: "151.69%",
    // letterSpacing: "0.045em",
    padding: "14px 70px",
    textAlign: "justify",
  },
  descriptionMob: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    // fontWeight: 500,
    fontSize: "20px",
    // lineHeight: "151.69%",
    // letterSpacing: "0.045em",
    // padding: "14px 70px",
    textAlign: "justify",
  },
  date: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "151.69%",
    letterSpacing: "0.045em",
    marginRight: "20px",
    marginBottom: "20px",
  },
}));
