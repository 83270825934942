import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Modal,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tabs,
  Tab,
  AppBar,
  Box,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  CircularProgress,
  Card,
} from "@material-ui/core";
import ReactPlayer from "react-player/lazy";
import Rating from "@material-ui/lab/Rating";

import clsx from "clsx";
// import IntroVideo from "../../assets/video/OmidIntro.mp4";
import {
  Close,
  Share,
  InsertInvitation,
  PlayCircleOutline,
  HighlightOff,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import CourseViewEpisodesCard from "./ViewCourse/CourseViewEpisodesCard/index";
import CourseInstructorIntroCard from "./ViewCourse/CourseInstructorIntroCard";
import CoursePlanCard from "./ViewCourse/CoursePlanCard";
import { useTheme } from "@material-ui/core/styles";
import { Edit } from "@material-ui/icons";
import useFetch from "use-http";
import { RELATE_APP_BASE_URL } from "../../utils/constants";
import { Link } from "gatsby";
import { useMediaQuery } from "@material-ui/core";

const IntroVideo =
  process.env.REACT_APP_INTRO_VIDEO ||
  "https://ipfsn.omidlife.com/ipfs/Qmf8khwqMWZ1DCT4AMQinJ4TYQcrRXUD1zSFZKUCEGKKCA";
function ViewCourseDesktop(props) {
  const { courseDetail } = props;
  console.log(courseDetail, "courseDetail");
  const classes = useStyles();
  // debugger;

  const [previewDialog, setPreviewDialog] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [joinDialog, setJoinDialog] = useState(false);
  const [subscribeDialog, setSubscribeDialog] = useState(false);

  const [courseStatus, setCourseStatus] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [modalType, setModalType] = useState("");
  const [noEpisode, setNoEpisode] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [recommendUpgrade] = useState(false);
  const [value, setValue] = useState(0);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [errors, setErrors] = useState(false);
  const [messages, setMessages] = useState("");
  const [preview, setPreview] = useState(false);
  const [introVideo, setIntroVideo] = useState(true);
  const [subscriptionExpire, setSubscriptionExpire] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [configs, setConfigs] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(true);
  const [imageSrcPosition, setImageSrcPosition] = useState(0);
  const [image, setImage] = useState();
  const { get, response } = useFetch(`${process.env.REACT_APP_API_URL}`);

  const handleClosePreviewDialog = () => {
    setPreviewDialog(false);

    setThumbnail(null);
    setIntroVideo(true);
  };

  useEffect(() => {
    if (courseDetail?.id) {
      setCourseStatus(courseDetail?.status === "Active" ? true : false);
    }
  }, [courseDetail]);

  function preloadImage(src) {
    var ext = src.split(".");
    var text = ext[2] + "_thumb";
    ext.splice(2, 1, text);
    let newUrl = ext.join(".");
    console.log(ext, "******");
    console.log(newUrl, "*newUrl*****");
    // let result = src.replace("png", "webp");
    setImage(src);
    // setImage(src);
  }

  /*  useEffect(() => {
    preloadImage(`${RELATE_APP_BASE_URL}${courseDetail?.image}`);
  }, []); */

  useEffect(() => {
    async function fetchConfigs() {
      try {
        const _configs = await get("/config/");
        if (response.ok) {
          let obj = {};
          for (const item of _configs?.data) {
            obj = { ...obj, [item.key]: item.value };
          }
          setConfigs(obj);
        } else {
          fetchConfigs();
        }
      } catch (e) {}
    }

    fetchConfigs();
  }, []);

  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex && hex.slice(1, 3), 16),
      g = parseInt(hex && hex.slice(3, 5), 16),
      b = parseInt(hex && hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };
  const handleOpenPreviewDialog = () => {
    setPreviewDialog(true);
  };
  const handleEnded = () => {
    if (introVideo) {
      setIntroVideo(!introVideo);
    }
  };

  //  making the thumb image url

  /*  var src = courseDetail?.image;
  if (!src.includes(".webp")) {
    var ext = src.split(".");
    // console.log(ext);
    var text = ext[0] + "_thumb";
    // console.log(ext[0]);
    ext.splice(0, 1, text);

  // var newUrl = ext.join(".");
  let newUrl = text + ".webp";
  newUrl = img_src; */
  // debugger;

  // console.log("image url " + newUrl);

  const courseViewHeroImageCard = (
    <div
      title={courseDetail?.name}
      className={classes.root}
      style={{
        // backgroundImage: `url(${RELATE_APP_BASE_URL}${courseDetail?.image}),linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)`,
        // backgroundImage: `url(${RELATE_APP_BASE_URL}${newUrl}),linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)`,
        backgroundImage:
          imageSrcPosition > 1
            ? `url(${RELATE_APP_BASE_URL}${
                courseDetail?.image
              }&dim=${300}x${300}),linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)`
            : `url(${RELATE_APP_BASE_URL}${
                courseDetail?.image
              }?dim=${300}x${300}),linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)`,
        // backgroundImage: newUrl
        //   ? `url(${RELATE_APP_BASE_URL}${newUrl}),linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)`
        //   : `url(${RELATE_APP_BASE_URL}${courseDetail?.image}),linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)`,

        backgroundPosition: "center center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        height: 0,
        paddingTop: "56.25%",
        position: "relative",
      }}
    >
      <div
        className={classes.root}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Grid container style={{ height: "100%" }}>
          <Grid container justify="flex-end" spacing={1}>
            <Grid item xs={2} style={{ height: "25px" }}></Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  if (imageSrcPosition === 0) {
    let modalImageUrl = courseDetail?.image;
    let textPosition = modalImageUrl?.search("updatedAt");
    if (textPosition > 1) {
      setImageSrcPosition(textPosition);
    }
  }

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          style={{
            padding: isMobile ? "10px 10px 0px 10px" : "80px 20px 0px 20px",
          }}
        >
          <Grid container direction="row" style={{ margin: "0px 0px 0px 0px" }}>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.courseTitle}>
                {courseDetail?.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            xl={7}
            lg={7}
            container
            className={isMobile ? "" : classes.scroll}
            style={{ padding: isMobile ? "0px" : "0px 20px 0px 0px" }}
          >
            <Grid
              item
              xs={12}
              style={{
                marginLeft: "-20px",
                display: "flex",
                alignItems: "center",
                // height: "87px",
              }}
            >
              {/* <TopBar
                appBarConfig={{ show: true, text: "Course" }}
                style={{
                  color: "#068594",
                  backgroundColor: "#FCFCFC",
                  float: "left",
                }}
              /> */}
            </Grid>

            <Dialog
              // fullScreen={fullScreen}
              fullWidth={true}
              minWidth={"sm"}
              // maxWidth={'lg'}
              open={previewDialog}
              onClose={handleClosePreviewDialog}
              aria-labelledby="responsive-dialog-title"
              classes={{ root: classes.dialogRoot }}
            >
              <DialogContent style={{ background: "#fff" }}>
                {thumbnail ? (
                  <Card
                    elevation={0}
                    className={classes.ratio}
                    style={{ borderRadius: "0px", background: "#fff" }}
                  >
                    <img
                      src={`${RELATE_APP_BASE_URL}${courseDetail?.image}`}
                      // src={`${RELATE_APP_BASE_URL}${newUrl}`}
                      className={classes.imageCardRoot}
                      style={{ borderRadius: "0px" }}
                      alt={props?.courseDetail?.name}
                      loading="lazy"
                    />
                  </Card>
                ) : (
                  <div
                    className={classes.playerWrapper}
                    style={{ background: "#fff" }}
                  >
                    <div
                      className={clsx(classes.reactPlayer, classes.preLoadBody)}
                    >
                      {loadingProgress && (
                        <CircularProgress style={{ color: "#09A9BB" }} />
                      )}
                    </div>
                    <ReactPlayer
                      controls="true"
                      height="100%"
                      width="100%"
                      className={classes.reactPlayer}
                      // onProgress={handleProgress}
                      onEnded={handleEnded}
                      onStart={() => setLoadingProgress(false)}
                      playing={true}
                      // light={sessionThumbnail}
                      onError={() => {
                        previewDialog &&
                          setThumbnail(
                            `${RELATE_APP_BASE_URL}${courseDetail?.image}`
                          );
                      }}
                      url={
                        introVideo
                          ? IntroVideo
                          : courseDetail?.ipfsCid != null
                          ? `https://ipfsn.omidlife.com/ipfs/${courseDetail?.ipfsCid}`
                          : courseDetail?.previewUrl
                      }
                      config={{
                        youtube: {
                          playerVars: { showinfo: 1 },
                        },
                        file: {
                          attributes: {
                            preload: "none",
                            controlsList: "nodownload",
                          },
                        },
                      }}
                    />
                  </div>
                )}
              </DialogContent>
            </Dialog>
            <Grid item xs={12}>
              {courseViewHeroImageCard}
            </Grid>
            <Grid
              xs={12}
              container
              alignItem="flex-end"
              style={{ marginTop: "15px" }}
            >
              {courseDetail?.previewUrl && (
                <Grid item xs={6} sm={3}>
                  <Button
                    aria-label="Preview"
                    variant="contained"
                    color="default"
                    style={{ backgroundColor: "#09B1D3" }}
                    className={classes.startNowBtn}
                    // startIcon={<PlayArrow />}
                    onClick={handleOpenPreviewDialog}
                  >
                    Preview
                  </Button>
                </Grid>
              )}
              <Grid item xs={6} sm={3}>
                <Link
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  // to={"subscription-plans"}
                  to={
                    courseDetail?.plan?.name === "Subscription"
                      ? `/subscription-plans`
                      : `${RELATE_APP_BASE_URL}/view-course/${courseDetail?.slug}?tab=signup`
                  }
                >
                  <Button
                    className={classes.startNowBtn}
                    aria-label="Subscribe"
                    // style={{ backgroundColor: "#F3B527" }}
                  >
                    {courseDetail?.plan?.name === "Paid"
                      ? `Join for (${configs?.currency + courseDetail.price})`
                      : courseDetail?.plan?.name === "Freemium"
                      ? `Join for (${configs?.currency + courseDetail.price})`
                      : courseDetail?.plan?.name === "Subscription"
                      ? "Subscribe"
                      : "Join for free"}
                  </Button>
                </Link>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <CourseViewEpisodesCard
                category={courseDetail?.category}
                episodes={courseDetail?.episodes}
              />
            </Grid>
            {/* {courseDetail?.user?.id === authReducerState?.user?.id ? (
              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <AppBar position="static" className={classes.TabAppBar}>
                    <StyledTabs
                      value={value}
                      onChange={handleChange}
                      aria-label="styled tabs example"
                      classes={{
                        root: classes.tabLabel,
                        selected: classes.tabSelected,
                      }}
                    >
                      <StyledTab
                        label={intl.formatMessage({ id: "episodes" })}
                        classes={{
                          root: classes.tabLabel,
                          selected: classes.tabSelected,
                        }}
                      />
                      <StyledTab
                        label={intl.formatMessage({ id: "stats" })}
                        classes={{
                          root: classes.tabLabel,
                          selected: classes.tabSelected,
                        }}
                      />
                    </StyledTabs>
                  </AppBar>
                </Grid>
                <Grid item xs={12}>
                  <TabPanel
                    value={value}
                    index={0}
                    style={{ width: "100%", minHeight: "100vh" }}
                  >
                    <Grid container style={{ paddingTop: "20px" }}>
                      <CourseViewEpisodesCard
                        setOpen={
                          courseDetail?.isUserTaking
                            ? setOpenPaymentDialog
                            : () => {
                                setOpenModel(true);
                                setModalType("joinCourse");
                              }
                        }
                        category={courseDetail?.category}
                        episodes={courseDetail?.episodes}
                      />
                    </Grid>
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={1}
                    style={{ width: "100%", minHeight: "100vh" }}
                  >
                    <CourseStats />
                  </TabPanel>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <CourseViewEpisodesCard
                    setOpen={
                      courseDetail?.isUserTaking
                        ? setOpenPaymentDialog
                        : () => {
                            setOpenModel(true);
                            setModalType("joinCourse");
                          }
                    }
                    category={courseDetail?.category}
                    episodes={courseDetail?.episodes}
                  />
                </Grid>
              </>
            )} */}
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            xl={5}
            lg={5}
            container
            justifyContent="center"
            style={{ padding: "0px 15px 0px 15px", background: "#E8EEEF" }}
            className={isMobile ? "" : classes.scroll}
          >
            <Grid
              item
              xs={12}
              // style={{ marginTop: "87px" }}
              style={{ marginTop: "10px" }}
              justifyContent="center"
            >
              <CourseInstructorIntroCard
                instructor={courseDetail?.user}
                tags={courseDetail?.tags}
                category={courseDetail?.category}
                remarks={courseDetail?.remarks}
                status={courseDetail?.status}
                courseDetail={courseDetail}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{ marginTop: 15 }}
              justifyContent="center"
            >
              <CoursePlanCard courseDetail={courseDetail} />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h2"
                style={{
                  fontFamily: "Reef",
                  fontStyle: "normal",
                  fontWeight: 900,
                  fontSize: "22px",
                  paddingTop: "20px",
                  color: "#1B1B1B",
                  textAlign: "center",
                }}
              >
                {/* {intl.formatMessage({ id: "reviews" })} */}
              </Typography>
            </Grid>

            {/* <Grid item xs={12}>
              <CourseReviews courseReviews={courseDetail?.reviews_ratings} />
            </Grid>

            <Grid item xs={12}>
              {(courseDetail?.isUserTaking ||
                courseDetail?.user?.id === authReducerState?.user?.id) && (
                <PostReviewCard courseId={courseDetail?.id} />
              )}
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      {/* <CustomerCardList
        open={openPaymentDialog}
        setOpen={setOpenPaymentDialog}
        setRecommendUpgrade={setRecommendUpgrade}
        setShareOpen={setShareOpen}
        courseId={courseDetail?.id}
        plan={courseDetail?.plan}
        price={getPrice(
          courseDetail?.price,
          reducerUser?.purchasedSubscription,
          "course"
        )}
      /> */}
    </>
  );
}

export default ViewCourseDesktop;

const useStyles = makeStyles((theme) => ({
  button1: {
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    height: "52px",
  },
  startNowBtn: {
    height: "2.463em",
    // background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    background: "#088A2D",
    width: "95%",
    // minWidth: "120px",

    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: "Reef",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "8px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#088A2D",
      color: "#fff",
    },
    "&:focus": {
      background: "#088A2D",
      color: "#fff",
    },
  },
  TabAppBar: {
    backgroundColor: "#FFF",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  tabLabel: {
    color: "#666",
    // fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    minWidth: "20%",
    textTransform: "none",
    textAlign: "left",
  },
  tabSelected: {
    color: "#47b8c4",
    // fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
  },
  root: {
    width: "100%",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  media: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  courseTitle: {
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    color: "#000",
    paddingBottom: "15px",
  },
  courseDescription: {
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    letterSpacing: "0.015em",
    color: "#FFFFFF",
    padding: "20px 0px",
  },
  proceedButton: {
    background: "#fbae02",
    color: "#fff",
    "&:hover": {
      background: "#fbae02",
      color: "#fff",
    },
    "&:focus": {
      background: "#fbae02",
      color: "#fff",
    },
  },
  subscribeButton: {
    background: "#088A2D",
    color: "#fff",
    "&:hover": {
      background: "#088A2D",
      color: "#fff",
    },
    "&:focus": {
      background: "#088A2D",
      color: "#fff",
    },
  },

  /*   startNowBtn: {
    height: "2.463em",
    width: "95%",
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",
    color: "#FFFFFF",
  }, */
  subscribeBtn: {
    height: "2.463em",
    width: "95%",
    background: "linear-gradient(360deg, #09A9BB 0%, #A6D6FF 100%)",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  CourseImageCardText: {
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "10px",
    color: " #FFFFFF",
  },
  instructorIntroText: {
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
  },
  instructorIntroTextTitle: {
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1B1B1B",
  },
  instructorIntroTextSubTitle: {
    fontSize: "16px",
    lineHeight: "22px",
    color: "#B0B0B0",
    letterSpacing: "0.015em",
    padding: "10px 0",
  },
  courseTags: {
    background:
      "linear-gradient(180deg, rgba(123, 146, 166, 0.95) 0%, rgba(21, 57, 89, 0.95) 100%)",
    borderRadius: "60px",
    transform: "rotate(3600deg)",
    marginRight: "3px",
  },

  episodesCardContainer: {
    width: "100%",
    minHeight: "19.750em",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    // backgroundColor: theme.palette.background.paper,
    outline: "none",
  },

  modelBodyText: {
    // fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
  },

  startTimeTxt: {
    // fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,

    fontSize: "20px",
    lineHeight: "10px",
    color: "#15AEBF",
  },
  msgBody: {
    minHeight: "200px",
    alignItems: "center",
    display: "flex",
  },
  readMore: {
    fontSize: "12px",
    cursor: "pointer",
    color: "#C4C4C4",
    "&:hover": {
      textDecoration: "underline",
      color: "#84CDF0",
    },
  },
  scroll: {
    height: "100vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
    },
  },
  heartIconStyle: {
    paddingTop: "15px",
    cursor: "pointer",
    marginRight: "15px",
  },
  button: {
    textTransform: "none",
    color: "#09B1D3",
    cursor: "pointer",
    fontSize: "70px",
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
    height: 0,
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },

  ratio: {
    position: "relative",
    width: "100%",
    height: 0,

    paddingTop: `56.25%`,
  },
  preLoadBody: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#a9a9a9",
    width: "100%",
  },
  closeIcon: {
    color: "#a9a9a9",
    float: "right",
    cursor: "pointer",
  },
}));
