import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Typography, CardContent, Grid } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";

function CourseInstructorIntro(props) {
  const classes = useStyles();
  const { courseDetail } = props;

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Grid container>
            <>
              <Grid item xs={12}>
                <Grid container>
                  <Typography
                    variant="h2"
                    className={clsx(classes.font_family, classes.heading)}
                  >
                    Course Price
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  className={clsx(classes.font_family, classes.description)}
                >
                  {courseDetail?.plan?.name === "Subscription"
                    ? "Under Subscription"
                    : courseDetail?.plan?.name === "Free" ||
                      courseDetail?.plan?.name === "Donation"
                    ? "Free"
                    : ` ${courseDetail?.price}`}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Typography
                    variant="h2"
                    className={clsx(classes.font_family, classes.heading)}
                  >
                    Course Type
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  className={clsx(classes.font_family, classes.description)}
                >
                  {courseDetail?.user?.isPlatform
                    ? "Platform Course"
                    : "Teacher Course"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Typography
                    className={clsx(classes.font_family, classes.heading)}
                  >
                    Course Teacher
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  className={clsx(classes.font_family, classes.description)}
                >
                  {courseDetail?.user?.name ?
                     courseDetail?.user?.name
                    : "--"}
                </Typography>
              </Grid>
            </>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default CourseInstructorIntro;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0px 16px 20px rgba(123, 0, 0, 0.05)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    background: "#fff",
  },
  font_family: {
    fontFamily: "Reef",
    fontStyle: "normal",
    fontWeight: 900,
  },
  heading: {
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1B1B1B",
  },
  description: {
    fontSize: "16px",
    lineHeight: "26px",
    // color: "#B0B0B0",
    color: "#635e5e",
    letterSpacing: "0.016em",
  },
}));
